import React, { Component } from 'react';
import GatsbyLink from '../GatsbyLink';
import { compileQuery, getUrlVars } from '../../utils/helpers'
import { decodeEntities } from '../../utils/htmlParse';
import { navigate } from "gatsby"
import './CaseStudiesNavigation.scss';

const Options = (props) => {
  const { location, value, title, parent } = props
  return (
    <>
    <option value={value}>{title}</option>
    {location.edges.map(({node: subLocation}, index) => subLocation.wordpress_parent === parent && (
      <option key={index} value={subLocation.slug}> &nbsp;&nbsp; {decodeEntities(subLocation.name)}</option>
    ))}
    </>
  )
}

export default class CaseStudiesNavigation extends Component {

  navigateTo(event, reset = false) {
    var newDest = compileQuery(event.target.name,event.target.value,'page')
    if (newDest) {
      navigate( `/case-studies/filter${newDest}#filter`, { state: { fromFilter: window.scrollY }} )
    } else {
      navigate( `/case-studies/filter${newDest}#filter`, { state: { fromFilter: window.scrollY }} )
    }

  }

  clearFilter(event) {
    navigate( `/case-studies/#filter` )
  }


  render() {
    const {
      location = null,
      sector = null,
      caseStudies
    } = this.props;

    const pageAttr = getUrlVars()

    var incSectors = [];
    caseStudies && caseStudies.map(a => a.node.sector && a.node.sector.map(b => !incSectors.includes(b) && incSectors.push(b) ))

    return (
      <section className="CaseStudiesNavigation">
        <div id="filter"/>
        <span className="filter-by-label">Filter By:</span>
        <div className="select-group">
          <div className="select-container">
            <select name="sector" onChange={e => this.navigateTo(e)} defaultValue={pageAttr.sector ? pageAttr.sector : 'null'}>
              <option value="null">Sector</option>
              {sector && sector.edges.map(({node: item}, index) => item.wordpress_parent === 0 && (
                <option key={index} value={item.slug}>{decodeEntities(item.name)}</option>
              ))}
            </select>
          </div>
          <div className="select-container">
            <select name="location" onChange={e => this.navigateTo(e, true)} defaultValue={pageAttr.location ? pageAttr.location : 'null'}>
              <option value="null">Location</option>
              {location && location.edges.map(({node: item}, index) => item.wordpress_parent === 0 && (
                <Options key={index} parent={item.wordpress_id} location={location} value={item.slug} title={decodeEntities(item.name)}/>
              ))}
            </select>
          </div>
          {sector && sector.edges.map(({node: itema}, index) => itema.wordpress_parent === 0 && pageAttr && pageAttr.sector === itema.slug && (
            <div className="select-container" key={index}>
              <select name="value" onChange={e => this.navigateTo(e)} defaultValue={pageAttr.value ? pageAttr.value : 'null'}>
                <option value="null">{`All ${itema.name}`}</option>
                {sector && sector.edges.map(({node: item}, index) => item.wordpress_parent === itema.wordpress_id && (
                  <Options key={index} parent={item.wordpress_id} location={sector} value={item.slug} title={decodeEntities(item.name)}/>
                ))}
              </select>
            </div>
          ))}

        </div>
        {pageAttr &&
          <div className="clear-filter">
            <button onClick={e => this.clearFilter(e)}>Clear Filter</button>
          </div>
        }
      </section>
    );
  }
}
