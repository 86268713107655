import React, { Component } from 'react'
import GatsbyLink from '../GatsbyLink'
import { decodeEntities } from '../../utils/htmlParse'
import { Image } from '../Image'
import './CaseStudiesList.scss'

const ListItem = props => {
  const { title, slug, featuredImage, location, sector, acf } = props.item
  const locationString = location
    .map(
      l => props.location.edges.find(e => e.node.wordpress_id === l).node.name
    )
    .join(', ')
  const sectorString = decodeEntities(
    sector
      .map(
        l => props.sector.edges.find(e => e.node.wordpress_id === l).node.name
      )
      .join(' / ')
  )
  return (
    <div className="flex-item">
      <GatsbyLink
        to={`/case-studies/${slug}/`}
        decode={false}
        className="flex-item__link"
      >
        <div className="image-container">
          <Image
            className="featured-image"
            src={featuredImage}
          />
        </div>
        <div className="text-container">
          <div className="text-wrapper">
            <span
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {sectorString.length > 1 ? (
              <span className="sector">{sectorString}</span>
            ) : (
              ''
            )}
            {locationString.length > 1 ? (
              <span className="location">{locationString}</span>
            ) : (
              ''
            )}
            {acf.summary && acf.summary.length > 1 && <ReducedSummary
              summary={acf.summary}
              wordLimit={27}
              className={'summary-text'}
            />}
          </div>
          <div className="button">View Project</div>
        </div>
      </GatsbyLink>
    </div>
  )
}
// Functional component which takes in a large block of text
// and returns a shortened word limit version
const ReducedSummary = props => {
  const summaryText = props.summary;
  const classNameValue = props.className || '';
  let wordLimit = props.wordLimit || 25;

  let split = summaryText.split(' ');
  if (split.length < wordLimit) {
    wordLimit = split.length;
  }

  let reducedString = [];
  for (let i = 0; i < wordLimit; i++) {
    reducedString.push(split[i]);
  }

  reducedString.push('...');
  reducedString = reducedString.join(' ');
  return (
    <p className={classNameValue}>{reducedString}</p>
  )
}

export default class CaseStudiesList extends Component {
  render() {
    const { range, perPage, data, caseStudies } = this.props
    const { location, sector } = data
    if (!caseStudies || caseStudies.length < 1)
      return (
        <>
          <section className="CaseStudiesList">
            <div className="content-overlay">
              <div className="flex-row">
                <span>There are no case studies to show</span>
              </div>
            </div>
          </section>
        </>
      )
    return (
      <>

        <section className="CaseStudiesList">
          <div className="content-overlay">
            <div className="flex-row">
              {caseStudies.map((caseStudy, index) => {
                if (index >= range - perPage && index < range) {
                  return (
                    <ListItem
                      key={index}
                      item={caseStudy.node}
                      index={index}
                      location={location}
                      sector={sector}
                    />
                  )
                }
                if (!range && !perPage) {
                  return (
                    <ListItem
                      key={index}
                      item={caseStudy.node}
                      index={index}
                      location={location}
                      sector={sector}
                    />
                  )
                }
              })}
              <div className="flex-item hide-this"></div>
              <div className="flex-item hide-this"></div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
