import React from 'react';
import './TextBlock.scss';
import { Link } from 'gatsby'

export const TextBlock = props => {
  const { 
    theme,
    subheading,
    heading,
    content,
    button,
    buttonText,
    isDownloadFile,
    downloadFileLink,
    downloadFileButtonText,
  } = props
  return (
    <section className={`TextBlock right ${theme}`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <div className="content text-box tb2">
              { subheading && <span className="subtitle" dangerouslySetInnerHTML={{__html: subheading}} /> }
              { heading && <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} /> }
              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: content}} />
                {button && (
                      <Link
                        to={button}
                        activeClassName="active"
                        className={`button ${theme === 'dark' ? 'on-dark' : ''}`}
                        aria-current="true"
                      >
                        <span>{ buttonText ? buttonText : 'Learn More' }</span>
                      </Link>
                  )}
                {isDownloadFile && downloadFileLink && (
                  <a
                    href={downloadFileLink.url}
                    className={`button button-download ${theme === 'dark' ? 'on-dark' : ''}`}
                    aria-current="true"
                  >
                    { downloadFileButtonText ? downloadFileButtonText : 'Download' }
                  </a>
                )}
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}
